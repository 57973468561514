import { Component } from '@angular/core';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    async loadComponent() {
      const m = await import('./components/home/home.component');
      return m.HomeComponent;
    }
  },
  {
    path: 'work/:id',
    async loadComponent() {
      const m = await import('./components/work/work-details/work-details.component');
      return m.WorkDetailsComponent;
    }
  },
  {
    path: '**',
    async loadComponent() {
      const m = await import('./components/not-found/not-found.component');
      return m.NotFoundComponent;
    }
  }
];
